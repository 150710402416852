import { GoogleOAuthProvider } from '@react-oauth/google';
import GamificationWrapperApp from '_core/modules/components/gamified/GamificationWrapperApp/GamificationWrapperApp';
import { getCurrentLanguage } from '_core/utils/languages';
import { clientIdGoogle } from 'app/config/config';
import { gapi } from 'gapi-script';
import moment from 'moment';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastProvider } from 'react-toast-notifications';
import 'react-videolesson/dist/style.css';
import { PersistGate } from 'redux-persist/integration/react';
import AppContainer from './AppContainer';
import { I18nProvider, LayoutSplashScreen } from './_core';
import ToastCustom from './_core/modules/atoms/Toast';
import DialogGlobalPost from './_core/modules/components/dialogs/DialogGlobalPost/DialogGlobalPost';
import SocketsContainer from './app/containers/SocketsContainer';
import { Routes } from './app/router/Routes';
import './app/utils/skinAssets/icons';
import './app/utils/skinAssets/icons-gradient';
import './app/utils/skinAssets/images';

export default function App({ store, persistor, basename }) {
  moment.locale(getCurrentLanguage());

  gapi.load('client:auth2', () => {
    gapi.auth2.init({
      clientId: clientIdGoogle[window.location.origin],
      plugin_name: 'chat',
    });
  });

  return (
    <GoogleOAuthProvider clientId={clientIdGoogle[window.location.origin]}>
      {/* Provide Redux store */}
      <Provider store={store}>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
            // disable_session_recording: true,
          }}
        >
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                <LastLocationProvider>
                  {/* Provide Metronic theme overrides. */}
                  {/*<ThemeProvider>*/}
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Provide `react-toast-notifications` which provides context for the Toast descendants  */}
                    <DialogGlobalPost open={true} message={'Mensaje de prueba'} />
                    <ToastProvider placement="bottom-left" components={{ Toast: ToastCustom }}>
                      {/* Render routes with provided `Layout`. */}
                      <Switch>
                        <Routes />
                      </Switch>
                      <GamificationWrapperApp />
                      <SocketsContainer />
                      <AppContainer />
                    </ToastProvider>
                  </I18nProvider>
                  {/*</ThemeProvider>*/}
                </LastLocationProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </PostHogProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}
