import { defaultConfig } from 'app/config/environment';
import axios from 'axios';

export const CONTENTS = 'cms/contents';
export const LESSONS = 'front/lessons';
export const ITEMS = 'front/lesson-items';
export const ITEMS_BATCH = 'front/lesson-items-batch';
export const JOBS = 'front/jobs';

export function getLesson(guid) {
  return axios.get(LESSONS + '/' + guid);
}

export function getLessonItems(guid) {
  return axios.get(LESSONS + '/' + guid + '/items');
}

export function deleteLessonItem(params) {
  return axios.delete(ITEMS, { data: params });
}

export function deleteContent(params) {
  return axios.delete(CONTENTS, { data: params });
}

export function getContent(guid) {
  return axios.get(CONTENTS + '/' + guid);
}

export function createContent(params) {
  return axios.post(CONTENTS, params);
}

export function createLessonItem(params) {
  return axios.post(ITEMS, params);
}

export function createLessonItemsBatch(params) {
  return axios.post(ITEMS_BATCH, params);
}

export function getTicket(guid) {
  return axios.get(CONTENTS + '/' + guid + '/content/upload', {
    headers: {
      'app-referer': defaultConfig.API_URL,
    },
  });
}

export function reorderLessonItems(params) {
  return axios.put(ITEMS, params);
}

export function createTest(params) {
  return axios.post(CONTENTS, params);
}

export function addTestToLesson(params) {
  return axios.post(ITEMS, params);
}

export function updateTest(params) {
  return axios.put(ITEMS + '/' + params.guid, params);
}

export function updateContent(params) {
  return axios.put(CONTENTS + '/' + params.guid, params);
}

export function getContents(params) {
  return axios.get(CONTENTS, { params });
}

export function markContent(params) {
  return axios.put(JOBS + '/' + params.guid, params);
}

export function getJob(params) {
  return axios.get(JOBS + '/' + params.guid + `?course=${params.courseGuid}&lessonItem=${params.lessonItemGuid}`);
}

export function getJobs(params) {
  return axios.get(ITEMS + '/' + params);
}
