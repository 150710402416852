import { addLatex } from '_core/utils/katex/config';

import axios from 'axios';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-lemonade-editor/dist/index.css';
import 'react-mint-editor/dist/style.css';
import 'tangerine-viewer/dist/style.css';
import App from './App';
import { setupAxios } from './_core';
import * as env from './app/config/environment';
import store, { persistor } from './app/store/store';
import './index.scss';
import * as serviceWorker from './serviceWorker';

addLatex('katex');

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Inject Frontoffice interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Frontoffice mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

const { API_URL } = env[process.env.REACT_APP_ENV] || env['defaultConfig'];
setupAxios(axios, API_URL, store);

/**
 * Add langs resources from translation folder
 */

// Object.keys(resources).forEach(function (lang) {
//   resources[lang].map((langScope) => {
//     Object.keys(langScope).forEach(function (langScopeNs) {
//       i18next.addResources(lang, langScopeNs, langScope[langScopeNs]);
//     });
//   });
// });

ReactDOM.render(<App store={store} persistor={persistor} basename={PUBLIC_URL} />, document.getElementById('root'));

console.table([{ name: process.env.REACT_APP_WEBSITE_NAME, version: process.env.REACT_APP_VERSION, date: '2024.09.12' }]);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
